import React from 'react';
import PropTypes from 'prop-types';
import { EventPicker } from '@v2/components/ui/EventPicker';
import { NumberTextField } from '@v2/components/ui/NumberTextField';
import { Stack } from '@mui/material';
import { Controller, useWatch } from 'react-hook-form';
import { fields, objectives, goalTypes } from './constants';

export const GoalTypeAndAmount = ({
  control,
  value,
  resetField,
  ...props
}) => {
  const goalType = useWatch({
    control,
    name: fields.objectiveGoalType.path,
  });

  return (
    <Stack direction='row' spacing={2}>
      <Controller
        control={control}
        name={fields.objectiveGoalType.path}
        shouldUnregister
        render={({ field, fieldState }) => {
          return (
            <EventPicker
              {...field}
              {...fieldState}
              {...props}
              id={value}
              label="Goal Type"
              helperText={fieldState.error?.message}
              eventSet={objectives[value]?.goalTypes ?? []}
              onChange={(e) => {
                field.onChange(e);
                resetField(fields.objectiveGoalValueMultiplier.path);
                resetField(fields.objectiveGoalValueCost.path);
              }}
            />
          );
        }}
      />
      {goalTypes[goalType] &&
      <Controller
        control={control}
        name={goalTypes[goalType]?.goalValue.path}
        shouldUnregister
        render={({ field, fieldState }) => {
          return (
            <NumberTextField
              {...field}
              {...fieldState}
              fullWidth
              placeholder={goalTypes[goalType]?.goalValue?.placeHolder}
              label="Goal Value"
              decimalScale={goalTypes[goalType]?.goalValue?.decimalScale}
              inputAdornment={goalTypes[goalType]?.goalValue?.symbol}
              helperText={fieldState.error?.message}
              value={field.value || ''}  // force re-render
            />
          );
        }}
      />}
    </Stack>
  );
};

GoalTypeAndAmount.propTypes = {
  control: PropTypes.any.isRequired,
  eventSet: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
  resetField: PropTypes.func.isRequired,
};
