import { Stack } from '@mui/material';
import { advancedModals } from '@v2/components/campaign/CampaignAdGroupSection';
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { FormProvider } from 'react-hook-form';
import { fields } from '../../CampaignWizard/AdGroupSettingsSection/formConfig';
import { sectionProps } from '../../Wizard/types';
import { handleErrors } from '../utils';
import { labels } from './constants';
import { useGroupSection } from './hooks/useGroupSection';
import { wizardGeneralErrorFields } from '../constants';

const AdGroupInfoPane = lazy(() => import('./panes/AdGroupInfoPane'));
const AdGroupAdvancedDeliveryPane = lazy(
  () => import('./panes/AdGroupAdvancedDeliveryPane'),
);
const AdGroupAdvancedAudiencePane = lazy(
  () => import('./panes/AdGroupAdvancedAudiencePane'),
);
const AdGroupZipCodesPane = lazy(() => import('./panes/AdGroupZipCodesPane'));
const AdGroupCustomInventoryPane = lazy(
  () => import('./panes/AdGroupCustomInventoryPane'),
);
const AdGroupAdvancedInventoryPane = lazy(
  () => import('./panes/AdGroupAdvancedInventoryPane'),
);

const AdGroupAdvancedCreativesPane = lazy(
  () => import('./panes/AdGroupAdvancedCreativesPane'),
);

const GroupSection = ({
  group: adGroup,
  campaignId,
  sectionKey,
  isDisplay,
}) => {
  const {
    ref,
    form,
    sectionsInfo,
    mutating,
    campaign,
    isActivePane,
    submit,
    submitCustomInventory,
    submitCreatives,
    submitZipCodes,
    submitAudiences,
    submitAdvancedInventory,
    submitAdvancedDelivery,
    generalError,
    inventoryAvailability,
    activePane,
  } = useGroupSection({
    sectionKey,
    campaignId,
    adGroup,
    isDisplay,
  });

  return (
    <Stack ref={ref}>
      <FormProvider {...form}>
        {(!activePane || isActivePane(labels.nameDates.value)) && (
          <AdGroupInfoPane
            key="info"
            form={form}
            adGroup={adGroup}
            sectionsInfo={sectionsInfo}
            onSubmit={handleErrors(submit, {
              ...form,
              generalError,
              generalErrorFields: [
                ...wizardGeneralErrorFields,
                fields.targeting.path,
              ],
            })}
            mutating={mutating}
            campaign={campaign}
            isDisplay={isDisplay}
            inventoryAvailability={inventoryAvailability}
            {...sectionProps}
          />
        )}
        {isActivePane(labels.bidStrategy.value) && (
          <AdGroupAdvancedDeliveryPane
            key={advancedModals.bidStrategy}
            onSubmit={submitAdvancedDelivery}
            data={{
              frequencyCap: form.getValues(
                fields.configureAdGroupFrequencyCap.path,
              ),
              dayparting: form.getValues(
                fields.configureAdGroupDayparting.path,
              ),
            }}
            {...sectionProps}
          />
        )}
        {isActivePane(labels.audience.value) && (
          <AdGroupAdvancedAudiencePane
            key={advancedModals.audience}
            selected={form.getValues(fields.advancedAudience.path)}
            onSubmit={submitAudiences}
            adGroup={adGroup}
            {...sectionProps}
            inventoryAvailability={inventoryAvailability}
          />
        )}
        {isActivePane(labels.geography.value) && (
          <AdGroupZipCodesPane
            key={advancedModals.geography}
            adGroupId={adGroup}
            onSubmit={submitZipCodes}
            {...sectionProps}
          />
        )}
        {isActivePane(labels.customInventory.value) && (
          <AdGroupCustomInventoryPane
            key={advancedModals.customInventory}
            selected={form.getValues(fields.advancedCustomInventory.path)}
            onSubmit={submitCustomInventory}
            adGroup={adGroup}
            {...sectionProps}
          />
        )}
        {isActivePane(labels.inventory.value) && (
          <AdGroupAdvancedInventoryPane
            key={advancedModals.inventory}
            adGroup={adGroup}
            selected={form.getValues(fields.advancedInventory.path)}
            onSubmit={submitAdvancedInventory}
            {...sectionProps}
          />
        )}
        {isActivePane(labels.creative.value) && (
          <AdGroupAdvancedCreativesPane
            key={advancedModals.creative}
            adGroup={adGroup}
            isDisplay={isDisplay}
            creatives={form.getValues(fields.creatives.path)}
            onSubmit={submitCreatives}
            {...sectionProps}
          />
        )}
      </FormProvider>
    </Stack>
  );
};

GroupSection.propTypes = {
  isDisplay: PropTypes.bool,
  ...sectionProps,
};

export default GroupSection;
