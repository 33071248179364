import { useEffect, useMemo } from "react";
import { getDomainForTheme, getTheme } from "../../helpers";

/**
 * A custom React hook that returns the current theme based on the current advertiser tenant.
 * It also handles advertiser context and domain redirection based on the current theme.
 */
export const useAppDeployment = ({
  currentAdvertiserId,
  orgTenant,
}: {
  currentAdvertiserId: string;
  orgTenant: string;
}) => {
  const theme = useMemo(() => {
    const isNbcuSubdomain = window.location.href.includes('peacock');
    return getTheme({ orgTenant, isNbcuSubdomain });
  }, [orgTenant]);

  // get advertiser context from url and store in local storage
  useEffect(() => {
    const url = new URL(window.location.href);
    const advertiserId = url.searchParams.get('advertiser');
    if (!advertiserId) return;
    localStorage.setItem('AdvertiserContext', advertiserId);
    url.searchParams.delete('advertiser');
    window.location.href = url.toString();
  }, []);

  // redirect to correct domain based on theme
  useEffect(() => {
    const currentUrl = window.location.href;
    const newDomain = getDomainForTheme({ theme, currentUrl });
    if (!currentAdvertiserId || !newDomain || newDomain === currentUrl) return;
    const nextUrl = new URL(newDomain);
    nextUrl.searchParams.set('advertiser', currentAdvertiserId);
    localStorage.removeItem('AdvertiserContext');
    window.location.href = nextUrl.toString();
  }, [theme, currentAdvertiserId]);

  return { theme };
}
