import moment from 'moment-timezone';
import { convertToStatus, removeNullValues } from '../../../utils';
import { extractIdFromUrl } from '@cypress/utils/helpers';
import { accountCategoriesObjectiveMap } from '@components/accountCategories';
import { fields } from '@v2/components/forms/CampaignForm/constants';
import { useCurrentSession } from '@components/hooks';

export const parseApiDataToFormValues = (values, objectivesAllowed) => {
  const { currentAdvertiser } = useCurrentSession();
  const recommendedCampaignObjective =
    getRecommendedCampaignObjectForAdvertiser(currentAdvertiser);

  const {
    id,
    startDate,
    endDate,
    budget,
    dailyBudget,
    draft,
    experimentType,
    experimentPlan,
    freqCaps = [],
    objective,
    displayPct,
  } = values || {};

  const advancedOptions = {
    experimentType,
    experimentPlan,
    freqCaps,
  };

  const defaultEndDate = moment().add(1, 'month').endOf('month');
  const defaultEndTime = moment(defaultEndDate).endOf('day');

  return {
    ...values,
    [fields.draft.path]: draft ?? true,
    [fields.status.path]: convertToStatus(values),
    [fields.startDate.path]: moment(startDate ?? undefined),
    [fields.startTime.path]: startDate ? moment(startDate) : moment(),
    [fields.endDate.path]: endDate ? moment(endDate) : id ? null : defaultEndDate,
    [fields.endTime.path]: endDate ? moment(endDate) : id ? null : defaultEndTime,
    [fields.budgetType.path]: 'daily_budget',
    [fields.lifetimeBudget.path]: budget ?? undefined,
    [fields.dailyBudget.path]: dailyBudget ?? undefined,
    [fields.displayPct.path]: Number(displayPct) ? true : false,
    [fields.objective.path]: objective ||
      (objectivesAllowed ? recommendedCampaignObjective : null),
    [fields.advancedOptions.path]: {
      ...advancedOptions,
      isIncremental: !!experimentType,
      isExperiment: !!experimentPlan,
      isCapAds: freqCaps.length > 0,
      freqCaps,
      experimentPlan: experimentPlan ?? undefined,
      experimentType: experimentType ?? undefined,
    },
  };
};

export const parseFormValuesToApiData = values => {
  const {
    name,
    advertiser,
    startDate,
    startTime,
    endDate,
    endTime,
    budgetType,
    dailyBudget,
    lifetimeBudget,
    objective,
    objectiveGoalEvent,
    objectiveGoalType,
    objectiveGoalValueCost,
    objectiveGoalValueMultiplier,
    advancedOptions,
    active,
    draft,
    displayPct,
  } = values || {};

  const {
    isIncremental,
    isExperiment,
    isCapAds,
    experimentPlan,
    experimentType,
    freqCaps,
  } = advancedOptions || {};

  return {
    name,
    budget: budgetType === 'lifetime_budget' ? lifetimeBudget : dailyBudget,
    budget_type: budgetType,
    start_date: combineDateAndTime(startDate, startTime ?? moment()),
    end_date: combineDateAndTime(endDate, endTime),
    experiment_plan: isExperiment ? experimentPlan : undefined,
    experiment_type: isIncremental ? experimentType : undefined,
    freq_caps: isCapAds ? freqCaps : [],
    advertiser,
    active: !!active,
    draft: !!draft,
    display_pct: displayPct ? 0.1 : 0,
    objective,
    objective_goal_event: objectiveGoalEvent,
    objective_goal_type: objectiveGoalType,
    objective_goal_value_cost: objectiveGoalValueCost,
    objective_goal_value_multiplier: objectiveGoalValueMultiplier,
  };
};

export const parseFormValuesToApiDataForPatch = values => {
  const {
    name,
    startDate,
    startTime,
    endDate,
    endTime,
    budgetType,
    dailyBudget,
    lifetimeBudget,
    objective,
    objectiveGoalEvent,
    objectiveGoalType,
    objectiveGoalValueCost,
    objectiveGoalValueMultiplier,
    advancedOptions,
    displayPct,
  } = values || {};

  const {
    isIncremental,
    isExperiment,
    isCapAds,
    experimentPlan,
    experimentType,
    freqCaps,
  } = advancedOptions || {};

  const apiData = {};

  if (name) apiData.name = name;
  if (dailyBudget || lifetimeBudget || budgetType) {
    apiData.budget = budgetType === 'lifetime_budget'
      ? lifetimeBudget
      : dailyBudget;
    apiData.budget_type = budgetType;
  }
  if (startDate) {
    apiData.start_date =
      combineDateAndTime(startDate, startTime ?? moment());
  }
  if (endDate) apiData.end_date = combineDateAndTime(endDate, endTime);
  if (advancedOptions) {
    apiData.experiment_plan = isExperiment ? experimentPlan : null;
    apiData.experiment_type = isIncremental ? experimentType : null;
    apiData.freq_caps = isCapAds
      ? freqCaps.map(cap => removeNullValues(cap))
      : [];
  }
  if (displayPct) apiData.display_pct = displayPct ? 0.1 : 0;
  if (objective) apiData.objective = objective;
  if (objectiveGoalEvent) apiData.objective_goal_event = objectiveGoalEvent;
  if (objectiveGoalType) apiData.objective_goal_type = objectiveGoalType;
  if (objectiveGoalValueCost) {
    apiData.objective_goal_value_cost = objectiveGoalValueCost;
  }
  if (objectiveGoalValueMultiplier) {
    apiData.objective_goal_value_multiplier = objectiveGoalValueMultiplier;
  }

  return apiData;
};

export const getRecommendedCampaignObjectForAdvertiser = advertiser => {
  if (advertiser?.category) {
    const categoryId = extractIdFromUrl(advertiser.category);
    if (categoryId) {
      return Object.keys(accountCategoriesObjectiveMap).find(key => {
        return accountCategoriesObjectiveMap[key].includes(
          parseInt(categoryId, 10),
        );
      });
    }
  }
  return null;
};

const combineDateAndTime = (date, time) => {
  if (!date || !time) return null;
  const dateMoment = moment(date);
  const timeMoment = moment(time);

  const combinedMoment = moment({
    year: dateMoment.year(),
    month: dateMoment.month(),
    day: dateMoment.date(),
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    second: timeMoment.second(),
  });

  return combinedMoment;
};
