import { useContext } from 'react';
import WizardNavigationContext from '../../providers/WizardNavigationContext';

export const useWizardNavigation = () => {
  const wizardNavigationContext = useContext(WizardNavigationContext);

  if (wizardNavigationContext === undefined) {
    throw new Error(
      'useWizardNavigation must be used within a WizardNavigationContextProvider'
    );
  }

  return wizardNavigationContext;
};