type PluralForm = 'singular' | 'plural';

type PluralCopies = {
  singular: (count: number) => string;
  plural: (count: number) => string;
};

export const usePluralCopy = (count: number) => {
  const plural: PluralForm = count > 1 ? 'plural' : 'singular';
  return (pluralCopies: PluralCopies): string => pluralCopies[plural](count);
};
