import React, { useState, useEffect, useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment-timezone';
import clsx from 'clsx';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  Typography,
  Checkbox,
  Chip,
  Box,
} from '@mui/material';

import AppHeader from '../AppHeader';
import { CreativeQCAlerts } from '../CreativeQCAlerts';
import SortableTableHead from '../ui/SortableTableHead';
import { useAPI } from '../hooks/api';
import { useSort } from '../hooks/sort';
import { usePermissions, useBWSync, useFlags } from '../hooks';
import { formatToUSD } from '../util';
import { qcStatusTooltips, Scopes } from '../../constants';
import AdvertiserContext from '../AdvertiserContext';

const PREFIX = 'CreativeIndexPage';

const classes = {
  link: `${PREFIX}-link`,
  noWrap: `${PREFIX}-noWrap`,
  height: `${PREFIX}-height`,
  stats: `${PREFIX}-stats`,
  tableCell: `${PREFIX}-tableCell`,
  tableHeader: `${PREFIX}-tableHeader`
};

const StyledAppHeader = styled(AppHeader)(() => ({
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '700',
  },

  [`& .${classes.noWrap}`]: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: 81,
  },

  [`& .${classes.height}`]: {
    // fontSize: '0.75rem',
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.stats}`]: {
    fontWeight: '700',
    // fontSize: '0.75rem',
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.tableCell}`]: {
    // fontSize: '0.75rem',
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },
}));


const CreativeIndexPage = () => {
  const adContext = useContext(AdvertiserContext);
  const { hasPermission } = usePermissions();
  const { showBWSync, renderBWSyncStatus } = useBWSync();
  const { flags } = useFlags();
  const { adGroupId, campaignId } = useParams();
  const { useGetAll, useGet } = useAPI();

  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  const [creativeAdGroups, setCreativeAdGroups] = useState([]);
  const [selected, setSelected] = useState([]);
  const [adGroup, setAdGroup] = useState({});
  const [CSOwner, setCSOwner] = useState(null);

  const {
    order,
    orderBy,
    handleRequestSort,
    getComparator,
    stableSort
  } = useSort();

  const headCells = useMemo(() => {
    const cells = [
      { id: 'id', sortable: true, label: 'ID', disablePadding: false },
      { id: 'name', sortable: true, label: 'Creative', disablePadding: false },
      { id: 'adGroupName', sortable: false, label: 'Ad Group', disablePadding: false },
      { id: 'start_date', sortable: false, label: 'Uploaded', disablePadding: false },
      { id: 'spend', sortable: true, label: 'Spend', disablePadding: false },
      { id: 'visits', sortable: true, label: 'Visits', disablePadding: false },
      { id: 'costPerVisit', sortable: true, label: 'Cost per visit', disablePadding: false },
    ];

    if (showQCFunctionality) {
      cells.push({ id: 'qcStatus', sortable: true, label: '', disablePadding: false });
    }

    if (showBWSync && flags.SYNC_CHECKS_ENABLED) {
      cells.push({
        id: 'adServerCheck',
        sortable: false,
        label: 'Ad Server Check',
        disablePadding: false,
      })
    }

    cells.push({ id: 'active', sortable: true, label: 'Status', disablePadding: false });

    return cells;
  }, [showQCFunctionality, showBWSync, flags.SYNC_CHECKS_ENABLED]);

  useEffect(() => {
    if (adContext.id !== null) {
      getAdGroup();
      getCSOwner();
    }
  }, [adContext]);

  const getCSOwner = async () => {
    try {
      const response = await useGet(adContext.cs_owner);

      setCSOwner(response);
    } catch (error) {
      console.error(error);
    }
  };

  async function getAdGroup() {
    try {
      const response = await useGet(`/lineitems/${adGroupId}`);
      setAdGroup(response);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getCreativeAdGroups();
  }, [])

  const getCreativeAdGroups = async () => {
    useGetAll('/creative_lineitems', [], results => {
      return setCreativeAdGroups(results);
    }, {
      params: {
        line_item_id: adGroupId,
        expand: 'creative',
      },
    });
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = creativeAdGroups.map((group) => group.creative_object.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const creativeStatusIconRender = (status) => {
    if (status === true) {
      return <Chip label="Active" color="primary" />;
    }

    return <Chip label="Inactive" />;
  };

  return (
    <StyledAppHeader>
      <Box m={5} mb={2}>
        <Typography>
          <strong><Link className={classes.link} to={`/campaigns/`}>Campaigns</Link></strong> &gt; <strong><Link className={classes.link} to={`/campaigns/${campaignId}/adgroups/${adGroupId}`}>Ad Groups</Link></strong> &gt; Creative
        </Typography>
      </Box>

      {showQCFunctionality && (
        <CreativeQCAlerts
          email={CSOwner?.email || 'support@tvscientific.com'}
          items={creativeAdGroups.map(item => (item.creative_object))}
        />
      )}

      <Box border={1} borderColor="grey.300" p={6} pt={4} m={4} borderRadius="20px">
        <Toolbar disableGutters>
          <Typography variant="h6" component="div">Creative</Typography>
        </Toolbar>
        <TableContainer>
          <Table>
            <SortableTableHead
              headCells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              onSelectAll={handleSelectAllClick}
            />

            <TableBody>
              {stableSort(
                creativeAdGroups,
                getComparator(order, orderBy)
              ).map((creativeAdGroup, index) => {
                const { creative_object: creative } = creativeAdGroup;
                const isItemSelected = isSelected(creative.id);
                const labelId = `creative-table-checkbox-${index}`;
                const {
                  id,
                  stats_caches_by_lineitem_id: stats_caches,
                  qc = {},
                } = creative;
                const stats = stats_caches ? stats_caches[adGroupId] : {};

                const mediaUploadDate = creative.media_upload_date === null ? 'n/a' : moment(creative.media_upload_date).format('l');
                const mediaUploadDateTitle = creative.media_upload_date === null ? 'n/a' : moment(creative.media_upload_date).format('YYYY-MM-DD HH:mm');

                const QCStatusTooltip = qcStatusTooltips[qc.status] || (() => null);

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={`${creative.id}-${index}`}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    data-testid="creative-table-row"
                  >
                    <TableCell className={clsx(classes.height)} padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell>{creative.id}</TableCell>
                    <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" align="left">
                      <Link data-testid={`creative-name-${creative.name}`} className={classes.link} to={`/campaigns/${campaignId}/adgroups/${adGroupId}/creative/${creative.id}`}>{creative.name}</Link>
                    </TableCell>
                    <TableCell className={clsx(classes.tableCell, classes.noWrap)} align="left">{adGroup.name}</TableCell>
                    <TableCell className={classes.tableCell} align="left" title={mediaUploadDateTitle}>{mediaUploadDate}</TableCell>
                    <TableCell className={clsx(classes.stats)} align="left">
                      {stats && stats.total_spend != null ? formatToUSD(stats.total_spend) : '-'}
                    </TableCell>
                    <TableCell className={clsx(classes.stats)} align="left">
                     {stats && stats.visit_count != null ? stats.visit_count : '-'}
                    </TableCell>
                    <TableCell className={clsx(classes.stats)} align="left">
                     {stats && stats.cost_per_visit != null ? formatToUSD(stats.cost_per_visit) : '-'}
                    </TableCell>

                    {showQCFunctionality && (
                      <TableCell className={clsx(classes.stats)} align="center">
                        <QCStatusTooltip items={qc.messages} />
                      </TableCell>
                    )}

                    {showBWSync && flags.SYNC_CHECKS_ENABLED && (
                      <TableCell
                        className={clsx(classes.tableCell)}
                        align="left"
                      >
                        {renderBWSyncStatus(creative)}
                      </TableCell>
                    )}

                    <TableCell className={clsx(classes.height)} align="left">{creativeStatusIconRender(creative.active)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </StyledAppHeader>
  );
}

export default CreativeIndexPage;
