import { useContext, useMemo } from 'react';
import { useCopy, useFlags, useCurrentSession } from '@hooks';
import {
  Copies as AdvancedDeliveryOptionsFormCopies,
} from '../../AdvancedDeliveryOptionsForm/constants';
import { Copies, fields } from '../constants';
import {
  getObjectivesOptions,
  getRecommendedCampaignObjectForAdvertiser,
} from '../utils';
import { getMinDate } from '@components/WizardContainers/validations';
import { Themes } from '@constants/themes';
import AdvertiserContext from '@components/AdvertiserContext';

export const useCampaignForm = ({
  campaignStaticDisplayAdGroups,
  control,
  endDate,
  isIncremental,
  resetField,
  startDate,
}) => {
  const { theme, bidstrategyevent_set = [] } = useContext(AdvertiserContext);

  const { currentAdvertiser } = useCurrentSession();

  const Copy = useCopy(Copies);

  const { flags, Flags } = useFlags();

  const AdvancedDeliveryOptionsFormCopy = useCopy(
    AdvancedDeliveryOptionsFormCopies,
  );

  const recommendedCampaignObjective = useMemo(
    () => getRecommendedCampaignObjectForAdvertiser(currentAdvertiser),
    [currentAdvertiser],
  );

  const objectivesOptions = useMemo(
    () => getObjectivesOptions({
      control,
      recommendedCampaignObjective,
      resetField,
      copies: Copy,
      objectiveGoalEventSet: bidstrategyevent_set,
    }),
    [control, recommendedCampaignObjective, resetField, bidstrategyevent_set],
  );

  const handleObjectivesChange = () => {
    resetField(fields.objectiveGoalEvent.path);
    resetField(fields.objectiveGoalType.path);
    resetField(fields.objectiveGoalValueMultiplier.path);
    resetField(fields.objectiveGoalValueCost.path);
  };

  const dragsAllowed = flags[Flags.USER_GETS_VERTICAL_CAMPAIGN_DRAGS]
    && theme !== Themes.NBCU;

  const objectivesAllowed =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS];

  const minEndDate = getMinDate(startDate, endDate);

  const campaignHasSavedDrag =
    campaignStaticDisplayAdGroups.length > 0 &&
    !campaignStaticDisplayAdGroups[0].temporary;

  const dragToggleDisabled = campaignHasSavedDrag || isIncremental;

  const dragToggleText = isIncremental ?
    'Display retargeting is not available for incrementality tests.' :
    Copy.dragToggleText;

  const dragTooltip = campaignHasSavedDrag ?
    'Retarget exposed audience with display ads. This cannot be toggled off \
    once the display ad group is saved.' :
    Copy.dragToggleText;

  const campaignObjectiveHeader = Copy.campaignObjectiveHeader;

  const trackingIsSetUp = bidstrategyevent_set.length > 0;

  return {
    AdvancedDeliveryOptionsFormCopy,
    bidstrategyevent_set,
    campaignObjectiveHeader,
    Copy,
    dragsAllowed,
    dragToggleDisabled,
    dragToggleText,
    dragTooltip,
    handleObjectivesChange,
    minEndDate,
    objectivesAllowed,
    objectivesOptions,
    trackingIsSetUp,
  };
};
