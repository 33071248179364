const USER_GETS_VERTICAL_CAMPAIGN = 'USER_GETS_VERTICAL_CAMPAIGN';
const USER_GETS_VERTICAL_CAMPAIGN_DRAGS = 'USER_GETS_VERTICAL_CAMPAIGN_DRAGS';
const USER_GETS_NBCU_BIZMAN_UI = 'USER_GETS_NBCU_BIZMAN_UI';
const USER_GETS_VERTICAL_INVENTORY_AVAILABILITY = 'USER_GETS_VERTICAL_INVENTORY_AVAILABILITY';
const USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS = 'USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS';
const SYNC_CHECKS_ENABLED = 'SYNC_CHECKS_ENABLED';

export const Flags = {
  USER_GETS_VERTICAL_CAMPAIGN,
  USER_GETS_VERTICAL_CAMPAIGN_DRAGS,
  USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS,
  USER_GETS_NBCU_BIZMAN_UI,
  USER_GETS_VERTICAL_INVENTORY_AVAILABILITY,
  SYNC_CHECKS_ENABLED,
};

export const DefaultFlags = {
  [USER_GETS_VERTICAL_CAMPAIGN]: false,
  [USER_GETS_VERTICAL_CAMPAIGN_DRAGS]: false,
  [USER_GETS_NBCU_BIZMAN_UI]: false,
  [USER_GETS_VERTICAL_INVENTORY_AVAILABILITY]: false,
  [USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS]: false,
  [SYNC_CHECKS_ENABLED]: false,
};
