import React from 'react';
import { createRoot } from 'react-dom/client';
import moment from "moment-timezone";

import Root from './components/Root';
import './styles/styles.scss'; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
import 'fontsource-noto-sans-jp';
import './favicon.ico'; // Tell webpack to load favicon.ico

// Set EST to default timezone
moment.tz.setDefault("America/New_York");

const container: HTMLElement | null = document.getElementById('app');
if (container) {
  const root = createRoot(container);
  root.render(React.createElement(Root));
} else {
  console.error("Failed to find the 'app' element");
}
