import { bundleTypes } from '@components/WizardContainers/GroupSection/constants';
import { hiddenCategories } from '@components/WizardContainers/GroupSection/panes/AdGroupCustomInventoryPane/constants';
import { useMemo } from 'react';
import { useDomain } from '../domain';
import { useLoadInfinite } from './general';

export const useGetBundles = type => {
  const domain = useDomain();

  const { items } = type
    ? useLoadInfinite('bundles/', {
        params: {
          type,
        },
      })
    : useLoadInfinite('bundles/', {});

  const bundlesFilter = bundle =>
    !bundle.ott && bundle.type && !hiddenCategories.includes(bundle.type);

  const peacockFilter = bundle =>
    bundle.type === bundleTypes.PEACOCK || bundle.private;

  const privateBundlesMap = bundle => ({
    ...bundle,
    type: bundle.private ? bundleTypes.PRIVATE : bundle.type,
  });

  const bundles = useMemo(() => {
    const filter = domain.peacock ? peacockFilter : bundlesFilter;

    return items.map(privateBundlesMap).filter(filter);
  }, [domain.peacock, items]);

  return { items: bundles };
};
