import moment from 'moment-timezone';
import { object } from 'yup';
import { fields } from './constants';

const defaultCampaignName = () => {
  const timestamp = moment().format('MMMM Do h:mma');
  return `${timestamp} Campaign`;
};

export const defaultValues = () => {
  const defaultVals = Object.entries(fields).reduce(
    (acc, [, value]) => ({
      ...acc,
      [value.path]: value.defaultValue,
    }), {});
  defaultVals[fields.name.path] = defaultCampaignName();
  return defaultVals;
};

const objectivesRelatedPaths = [
  fields.objective.path,
  fields.objectiveGoalEvent.path,
  fields.objectiveGoalType.path,
  fields.objectiveGoalValueCost.path,
  fields.objectiveGoalValueMultiplier.path,
];

export const validationSchema = ({ objectivesAllowed }) => {
  const schema = Object.keys(fields).reduce((acc, key) => {
    const nextField = fields[key];
    if (nextField.rule
      && (
        objectivesAllowed
        || !objectivesRelatedPaths.includes(nextField.path)
      )) {
      acc[nextField.path] = nextField.rule;
    }
    return acc;
  }, {});
  return object().shape(schema);
};
