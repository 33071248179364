import { weightingRotationOptions } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCreative';
import { omit, pick } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { RoutePaths } from '../../../../constants';
import { useQuery, useWizardNavigation } from '../../../hooks';
import {
  useCampaignAdGroupsPage,
  useCreateAdGroup,
  usePatchAdGroup,
} from '../../../hooks/apis/adGroups';
import { useGetAllowedTargeting } from '../../../hooks/apis/allowedTargeting';
import { useGetAudiences } from '../../../hooks/apis/audiences';
import { useGetAllBidStrategies } from '../../../hooks/apis/bidStrategy';
import { useGetBundles } from '../../../hooks/apis/bundles';
import { useGetCampaign } from '../../../hooks/apis/campaigns';
import {
  useGetCreativeLineItemTrigger,
  useGetCreatives,
  usePatchCreative,
  usePatchCreativeLineItem,
} from '../../../hooks/apis/creatives';
import {
  useDisplays,
  usePatchStaticDisplayCreative,
} from '../../../hooks/apis/displays';
import {
  useCampaignStaticDisplayAdGroupsPage,
  useCreateStaticDisplayAdGroup,
  usePatchStaticDisplayAdGroup,
} from '../../../hooks/apis/staticDisplayAdGroups';
import { removeNullValues } from '../../utils';
import { fields } from '../formConfig';
import { transformAdGroup } from '../utils';
import { useAdGroupSettingsSectionForm } from './useAdGroupSettingsSectionForm';
import { useSectionsInfo } from './useSectionsInfo';

export const useCampaignAdGroupSettingsSection = ({
  adGroup,
  sectionKey,
  campaignId,
  isDisplay,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const urlQuery = useQuery();
  const history = useHistory();
  const [settings] = useState({
    defaultInventory: true,
  });
  const { getNextSectionKey, activePaneKey, isActive } =
    useWizardNavigation();
  const { campaign } = useGetCampaign(campaignId);
  const { data: targeting } = useGetAllowedTargeting();
  const { items: bundles } = useGetBundles();
  const { data: library } = useGetCreatives();
  const { displays: displayLib } = useDisplays({ page: 1 });
  const { items: audiences } = useGetAudiences();
  const { mutate: mutateCampaignAdGroups } =
    useCampaignAdGroupsPage(campaignId);
  const mutateAdGroupsOption = { onSuccess: () => mutateCampaignAdGroups() };
  const { trigger: updateAdGroup, isMutating: isPatchMutating } =
    usePatchAdGroup();
  const { trigger: updateLineItemCreative, isMutating: isPostMutating } =
    usePatchCreativeLineItem();
  const { trigger: updateCreative } = usePatchCreative();
  const { trigger: createAdGroup } = useCreateAdGroup(mutateAdGroupsOption);

  const { mutate: mutateCampaignStaticDisplayAdGroups } =
    useCampaignStaticDisplayAdGroupsPage(campaignId);
  const mutateStaticDisplayAdGroupsOption = {
    onSuccess: () => mutateCampaignStaticDisplayAdGroups(),
  };
  const {
    trigger: updateStaticDisplayAdGroup,
    isMutating: isStaticDisplayPatchMutating,
  } = usePatchStaticDisplayAdGroup(mutateStaticDisplayAdGroupsOption);
  const { trigger: updateStaticDisplayCreative } =
    usePatchStaticDisplayCreative();
  const { trigger: createStaticDisplayAdGroup } =
    useCreateStaticDisplayAdGroup(mutateStaticDisplayAdGroupsOption);

  const { data: bidStrategies } = useGetAllBidStrategies();
  const { trigger: getCreativeLineItem } = useGetCreativeLineItemTrigger();

  const lib = library?.results ?? [];
  const inventoryAvailability = 3; // hardcoded, needs DS endpoint

  const { form } = useAdGroupSettingsSectionForm({
    adGroup,
    bidStrategies,
    bundles,
    library: isDisplay ? displayLib : lib,
    audiences,
    targeting,
    campaign,
  });

  const { info } = useSectionsInfo({ form });

  const handleGeneralError = message => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  const handleRegularSubmit = async () => {
    const values = form.getValues();
    const { creativesAdditionalData, ...rest } = transformAdGroup(values, {
      bidStrategies,
      campaign,
      targeting,
    });

    const res = await (adGroup?.id && !adGroup?.temporary
      ? updateAdGroup({ id: adGroup.id, ...rest })
      : createAdGroup(omit(rest, 'id')));

    const lineItems = await getCreativeLineItem(res.id);

    await Promise.all(
      creativesAdditionalData.map(
        async ({ id, weighting, name, click_url, pixels }) => {
          const updatedData = pick(
            lineItems.find(({ creative }) => creative === id),
            ['id', 'lineitem', 'creative'],
          );
          await updateCreative(
            removeNullValues({
              name,
              id: updatedData.creative,
              pixels,
              click_url: click_url === '' ? null : click_url,
            }),
          );

          return updateLineItemCreative(
            removeNullValues({
              id: updatedData.id,
              creative: updatedData.creative,
              lineitem: updatedData.lineitem,
              weighting,
            }),
          );
        },
      ),
    ).catch(e => console.log(e));

    urlQuery.set('sectionKey', getNextSectionKey(activePaneKey));

    history.replace({
      pathname: generatePath(RoutePaths.CAMPAIGN_ADGROUP_EDIT, {
        campaignId: campaign?.id,
        adGroupId: res?.id,
      }),
      search: urlQuery.toString(),
    });
  };

  const handleStaticDisplaySubmit = async () => {
    const values = form.getValues();
    const { creativesAdditionalData, ...rest } = transformAdGroup(values, {
      bidStrategies,
      campaign,
    });

    const res = await (adGroup?.id
      ? updateStaticDisplayAdGroup({ id: adGroup.id, ...rest })
      : createStaticDisplayAdGroup(rest));

    await Promise.all(
      res.creatives.map(async staticDisplayCreativeId => {
        const additionalData = creativesAdditionalData.filter(
          ({ id }) => id === staticDisplayCreativeId,
        )[0];

        await updateStaticDisplayCreative(
          removeNullValues({
            ...additionalData,
            id: staticDisplayCreativeId,
          }),
        );
      }),
    ).catch(e => console.log(e));

    urlQuery.set('sectionKey', getNextSectionKey(activePaneKey));

    history.replace({
      pathname: generatePath(RoutePaths.CAMPAIGN_ADGROUP_EDIT, {
        campaignId: campaign?.id,
        adGroupId: res?.id,
      }),
      search: urlQuery.toString(),
    });
  };

  const handleSubmit = () => {
    if (isDisplay) {
      return handleStaticDisplaySubmit();
    }

    return handleRegularSubmit();
  };

  const handleSubmitCreatives = ({ creatives, weightingRotation }) => {
    form.setValue(fields.creatives.path, creatives);
    form.setValue(
      fields.weightingRotation.path,
      weightingRotation
        ? weightingRotationOptions.weighted
        : weightingRotationOptions.random,
    );
  };
  const handleSubmitCustomInventory = ({ items }) => {
    form.setValue(fields.advancedCustomInventory.path, items);
  };

  const handleSubmitZipCodes = data => {
    form.setValue(fields.geography.path, [
      ...form.getValues(fields.geography.path),
      ...data,
    ]);
  };

  const handleSubmitAudiences = ({ items }) => {
    form.setValue(fields.advancedAudience.path, items);
  };

  const handleSubmitAdvancedInventory = items => {
    form.setValue(fields.advancedInventory.path, items);
  };

  const handleSubmitAdvancedDelivery = data => {
    form.setValue(
      fields.configureAdGroupFrequencyCap.path,
      data[fields.configureAdGroupFrequencyCap.path],
    );
    form.setValue(
      fields.configureAdGroupDayparting.path,
      data[fields.configureAdGroupDayparting.path],
    );
  };

  const isActiveSection = isActive(sectionKey);
  const { trigger, watch, setValue } = form;
  const [bidStrategyOption, inventoryOption, advancedCustomInventory] = watch(
    [
      fields.adGroupBidStrategy.path,
      fields.inventoryOption.path,
      fields.advancedCustomInventory.path,
    ],
  );

  useEffect(() => {
    if (isActiveSection) {
      trigger();
    }
  }, [trigger, isActiveSection, inventoryOption, advancedCustomInventory]);

  useEffect(() => {
    return () => {
      lib.forEach(({ preview_url }) => {
        URL.revokeObjectURL(preview_url);
      });
    };
  }, [lib]);

  useEffect(() => {
    setValue(fields.adGroupBidStrategyEvent.path, null);
  }, [bidStrategyOption]);

  return {
    form,
    campaign,
    sectionsInfo: info,
    settings,
    mutating:
      isPatchMutating || isPostMutating || isStaticDisplayPatchMutating,
    submit: handleSubmit,
    submitCreatives: handleSubmitCreatives,
    submitZipCodes: handleSubmitZipCodes,
    submitCustomInventory: handleSubmitCustomInventory,
    submitAdvancedInventory: handleSubmitAdvancedInventory,
    submitAudiences: handleSubmitAudiences,
    submitAdvancedDelivery: handleSubmitAdvancedDelivery,
    generalError: handleGeneralError,
    inventoryAvailability,
  };
};
