import React, { forwardRef } from 'react';
import {
  TextField,
  MenuItem,
} from '@mui/material';
import PropTypes from 'prop-types';

const EventPicker = forwardRef(
  ({ eventSet, id, label, ...props }, ref) => {
    return (
      <TextField
        {...props}
        sx={{ marginLeft: 0, marginBottom: 2.5 }}
        ref={ref}
        select
        fullWidth
        InputLabelProps={{ 'data-testid': id }}
        id={id}
        label={label}
      >
        {eventSet?.map(({ value, name }, idx) => (
          <MenuItem
            key={idx}
            value={value}
            data-testid={`${id}-${idx}`}
          >
            {name}
          </MenuItem>
        ))}
      </TextField>
    );
  });

EventPicker.displayName = 'EventPicker';

EventPicker.propTypes = {
  eventSet: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default EventPicker;
