import React, { Fragment } from 'react';

import {
  Stack,
  Box,
  styled,
} from '@mui/material';
import SubwayNode from './SubwayNode';
import SubwaySectionTitle from './SubwaySectionTitle';
import PropTypes from 'prop-types';
import { sectionNames } from '../../../../components/CampaignWizard/constants';

const StyledVerticalDivider = styled(Box)(({ theme, length }) => {
  const lengthList = {
    short: '20px',
    long: '40px',
  };
  return {
    width: '1px',
    height: lengthList[length],
    backgroundColor: theme.palette.grey[4],
    marginLeft: '11.5px',
  }
});

const SubwayMap = ({
  subwayMapNodesData,
  sectionKeys,
  activeSectionKey,
  activeNodeKey,
  handleSelect,
  addAdGroup,
  duplicateAdGroup,
  deleteAdGroup,
  ...props
}) => {
  let adGroupNo = 0;

  const getNodeStatus = (nodeInfo, sectionKey, nodeKey) => {
    if (sectionKey === activeSectionKey && nodeKey === activeNodeKey) {
      return 'active';
    } return nodeInfo.status ?? 'pending';
  }

  const handleTitleClick = (sectionKey) => {
    handleSelect(sectionKey);
  }

  const handleAddAdGroup = () => {
    addAdGroup();
  }

  const handleDuplicateAdGroup = (sectionKey) => {
    duplicateAdGroup(sectionKey);
  }

  const handleDeleteAdGroup = (sectionKey) => {
    deleteAdGroup(sectionKey)
  }

  const isLastSection = (sectionKey) => {
    return sectionKeys[sectionKeys.length - 1] === sectionKey;
  };

  const renderSectionNodes = (sectionNodesInfo, sectionKey) => {
    return sectionNodesInfo.map((nodeInfo) => {
      const nodeKey = nodeInfo.key;
      return (
        <Fragment data-testid="subway-node-fragment" key={`subway-node-fragment-${sectionKey}-${nodeKey}`}>
          <StyledVerticalDivider length='short' key={`short-divider-${sectionKey}-${nodeKey}`} />
          <SubwayNode
            label={nodeInfo.label}
            status={getNodeStatus(nodeInfo, sectionKey, nodeInfo.key)}
            key={`subway-node-${sectionKey}-${nodeKey}`}
          />
        </Fragment>
      )
    })
  }

  const renderSection = (sectionKey, sectionNodesInfo) => {
    const section = sectionNodesInfo.section;
    switch (section) {
      case sectionNames.adGroup:
        return renderAdGroupSection(sectionKey, sectionNodesInfo);
      case sectionNames.displayAdGroup:
          return renderStaticDisplayAdGroupSection(sectionKey, sectionNodesInfo);
      case sectionNames.addAdGroup:
        return renderAddAdGroupSection();
      default:
        // static means this section cannot be deleted or duplicated
        return renderStaticSection(sectionKey, sectionNodesInfo);
    }
  };

  const renderStaticSection = (sectionKey, sectionNodesInfo) => {
    return (
      <Fragment key={`fragment-${sectionKey}`}>
        <SubwaySectionTitle
          data-testid={sectionNodesInfo.name}
          title={sectionNodesInfo.name ?? ''}
          category={sectionNodesInfo.section}
          isExpanded={activeSectionKey === sectionKey}
          handleTitleClick={() => handleTitleClick(sectionKey)}
        />
        <Stack key={`subway-stack-${sectionKey}`} alignItems='flex-start' useFlexGap>
          {
            activeSectionKey === sectionKey &&
            renderSectionNodes(sectionNodesInfo.nodes_info, sectionKey)
          }
          {!isLastSection(sectionKey) && <StyledVerticalDivider length='long' key={`longDivider-${sectionKey}`} />}
        </Stack>
      </Fragment>
    )
  }

  const _renderAdGroupSection = (sectionKey, title, category, adGroupNo, sectionNodesInfo) => {
    return (
      <Fragment key={`fragment-${sectionKey}`}>
        <SubwaySectionTitle
          title={title ?? ''}
          category={category}
          adGroupNo={adGroupNo}
          isExpanded={activeSectionKey === sectionKey}
          handleTitleClick={() => handleTitleClick(sectionKey)}
          handleDuplicate={() => handleDuplicateAdGroup(sectionKey)}
          handleDelete={() => handleDeleteAdGroup(sectionKey)}
        />
        <Stack key={`subway-stack-${sectionKey}`} alignItems='flex-start' useFlexGap>
          {
            activeSectionKey === sectionKey &&
            renderSectionNodes(sectionNodesInfo.nodes_info, sectionKey)
          }
          {!isLastSection(sectionKey) && <StyledVerticalDivider length='long' key={`longDivider-${sectionKey}`} />}
        </Stack>
      </Fragment>
    )
  }

  const renderAdGroupSection = (sectionKey, sectionNodesInfo) => {
    adGroupNo += 1;
    return _renderAdGroupSection(
      sectionKey,
      sectionNodesInfo.name,
      sectionNodesInfo.section,
      adGroupNo,
      sectionNodesInfo
    );
  };

  const renderStaticDisplayAdGroupSection = (sectionKey, sectionNodesInfo) => {
    const adGroupSections = sectionKeys.filter(
      key => key.startsWith('adGroup') || key.startsWith('displayAdGroup')
    );

    const adGroupNo =
      adGroupSections.findIndex(key => key === sectionKey) + 1;

    return _renderAdGroupSection(
      sectionKey,
      sectionNodesInfo.name,
      sectionNodesInfo.section,
      adGroupNo,
      sectionNodesInfo,
    );
  }

  const renderAddAdGroupSection = () => {
    return (
      <Stack spacing={1.5} alignItems='flex-start' useFlexGap key='stack-add-ad-group'>
        <SubwayNode
          name='add_ad_group'
          label='Ad Group'
          status='add'
          handleClick={handleAddAdGroup}
        />
        {!isLastSection(sectionNames.addAdGroup) && <StyledVerticalDivider length='long' />}
      </Stack>
    )
  };

  return (
    <Stack spacing={1.5} useFlexGap alignItems='flex-start' {...props}>
      {sectionKeys.map((sectionKey) => {
        return renderSection(sectionKey, subwayMapNodesData[sectionKey]);
      })}
    </Stack>
  );
};

SubwayMap.propTypes = {
  subwayMapNodesData: PropTypes.object.isRequired,
  sectionKeys: PropTypes.array.isRequired,
  activeSectionKey: PropTypes.string.isRequired,
  activeNodeKey: PropTypes.string.isRequired,
  handleSelect: PropTypes.func.isRequired,
  addAdGroup: PropTypes.func.isRequired,
  duplicateAdGroup: PropTypes.func.isRequired,
  deleteAdGroup: PropTypes.func.isRequired,
};

export default SubwayMap;
