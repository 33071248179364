import {
  flatMap,
  get,
  isArray,
  isObject,
  isString,
  map,
  values,
} from 'lodash';
import { entityStatus } from './constants';

export const removeNullValues = (obj = {}, filter = v => v) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === null || value === undefined || !filter(value)) {
      return acc;
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {});
};

export const getDirtyValues = ({ dirtyFields, values }) => {
  const fieldNames = Object.entries(dirtyFields).reduce(
    (acc, [key, value]) => {
      if (value) {
        return [...acc, key];
      }
      return acc;
    },
    [],
  );
  const dirtyValues = fieldNames.map(key => get(values, key));
  return fieldNames.reduce((acc, key, index) => {
    return { ...acc, [key]: dirtyValues[index] };
  }, {});
};

export const removeEmptyValues = (obj = {}) =>
  removeNullValues(obj, v => (isString(v) ? !!v : v));

export const convertToStatus = entity => {
  if (!entity) {
    return entityStatus.pending;
  }

  if (entity.draft) {
    return entityStatus.draft;
  }

  if (!entity.active && !entity.draft) {
    return entityStatus.paused;
  }

  return entityStatus.active;
};

const fetchErrors = error => {
  if (isArray(error)) {
    return map(error, fetchErrors);
  }

  if (isObject(error)) {
    return flatMap(values(error), fetchErrors);
  }

  return error;
};

const processErrors = (
  errors,
  { generalError, setError, generalErrorFields = [], mappedFields = {} },
) => {
  Object.keys(errors).forEach(key => {
    if (generalErrorFields?.includes(key)) {
      generalError?.(errors[key][0]);
    }

    const error = errors[key];

    if (isObject(error) && !isArray(error)) {
      processErrors(error, {
        generalError,
        setError,
        generalErrorFields,
        mappedFields,
      });
      return;
    }

    setError(mappedFields[key] ?? key, {
      type: 'server',
      message: fetchErrors(errors[key]),
    });
  });
};

export const handleErrors =
  (
    cb,
    { setError, generalError, generalErrorFields = [], mappedFields = {} },
  ) =>
  async (...params) => {
    try {
      return await cb(...params);
    } catch (error) {
      const errors = error?.response?.data;
      if (errors) {
        processErrors(errors, {
          generalError,
          setError,
          generalErrorFields,
          mappedFields,
        });
      } else {
        throw error;
      }
    }
  };

export const getValues = (data = {}, keys = []) =>
  keys.reduce((acc, key) => [...acc, get(data, key)], []);
