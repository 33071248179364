import React from 'react';
import { extractIdFromUrl } from '@cypress/utils/helpers';
import { accountCategoriesObjectiveMap } from '@components/accountCategories';
import { Stack } from '@mui/material';
import { GoalEventPicker } from './GoalEventPicker';
import { GoalTypeAndAmount } from './GoalTypeAndAmount';
import { objectives, objectiveValues, trackingLockedInfo } from './constants';

export const getUnlockedContent = (objective, contentsProps) => {
  const { control, resetField, objectiveGoalEventSet } = contentsProps;
  return (
    <Stack spacing={3} sx={{ marginBottom: 2.5 }}>
      {objective.hasGoalEvent &&
        <GoalEventPicker
          control={control}
          eventSet={objectiveGoalEventSet}
          value={objective.value}
        />
      }
      {objective.goalTypes?.length > 0 &&
        <GoalTypeAndAmount
          control={control}
          value={objective.value}
          resetField={resetField}
        />
      }
    </Stack>
  );
};

export const getLockedStatus = (option, disabledObjectiveOptions) => {
  switch (option.value) {
    case objectiveValues.appInstalls:
    case objectiveValues.websiteSales:
    case objectiveValues.websiteVisits:
      return !disabledObjectiveOptions || disabledObjectiveOptions.length === 0;
    default:
      return false;
  }
};

export const getLockedInfo = (option, lockedInfo) => {
  switch (option.value) {
    case objectiveValues.appInstalls:
    case objectiveValues.websiteSales:
    case objectiveValues.websiteVisits:
      return lockedInfo.trackingLockedInfo;
    default:
      return null;
  }
};

export const getRecommendedCampaignObjectForAdvertiser = advertiser => {
  if (advertiser?.category) {
    const categoryId = extractIdFromUrl(advertiser.category);
    if (categoryId) {
      return Object.keys(accountCategoriesObjectiveMap).find(key => {
        return accountCategoriesObjectiveMap[key].includes(
          parseInt(categoryId, 10),
        );
      });
    }
  }
  return null;
};

export const getObjectivesOptions = ({
  control,
  recommendedCampaignObjective,
  resetField,
  copies,
  objectiveGoalEventSet,
}) => {
  objectiveGoalEventSet.forEach(obj => {
    obj.value = obj.id;
  });

  const contentsProps = { control, resetField, objectiveGoalEventSet };

  return Object.keys(objectives).map(key => ({
    ...objectives[key],
    recommended: recommendedCampaignObjective === objectives[key].value,
    recommendedInfo: copies.objectiveRecommendation,
    unlockedContent: getUnlockedContent(objectives[key], contentsProps),
    locked: getLockedStatus(objectives[key], objectiveGoalEventSet),
    lockedInfo: getLockedInfo(objectives[key], { trackingLockedInfo }),
  }));
};
