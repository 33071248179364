import React from 'react';
import { Themes } from '../constants';

const AdvertiserContext = React.createContext({
  id: null,
  name: '',
  theme: Themes.NBCU,
  cost_model: null,
  primary_org: '',
  updateAdvertiser:null ,
  url: ''
});

AdvertiserContext.displayName = 'AdvertiserContext';

export default AdvertiserContext;
