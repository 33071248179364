import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  Menu,
  MenuItem,
  Grid,
  styled,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';

const StyledMenuItem = styled(MenuItem)(({ theme, type }) => ({
  '&.MuiMenuItem-root': {
    color: type === 'warning' ? theme.palette.red[1] : 'inherit',
  }
}));

const MenuIcon = ({ handleDuplicate, handleDelete, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDuplicateClick = () => {
    handleDuplicate();
    handleClose();
  };
  const handleDeleteClick = () => {
    handleDelete();
    handleClose();
  };

  return (
    <Grid
      item
      justifyContent="flex-end"
      alignItems="center"
      {...props}
    >
      <IconButton
        id="basic-button"
        size="small"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
      >
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </IconButton>
      <Menu
        id="basic-menu"
        display="flex"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          'data-testid': 'basic-menu-button'
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledMenuItem
          data-testid="duplicate-ad-group-option" // Correctly added data-testid
          onClick={handleDuplicateClick}
        >
          Duplicate Ad Group
        </StyledMenuItem>
        <StyledMenuItem
          data-testid="delete-ad-group-option" // Correctly added data-testid
          onClick={handleDeleteClick}
          type="warning"
        >
          Delete Ad Group
        </StyledMenuItem>
      </Menu>
    </Grid>
  );
};

MenuIcon.propTypes = {
  handleDuplicate: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default MenuIcon;
