import { advancedModals } from '@v2/components/campaign/CampaignAdGroupSection';
import { fields } from './formConfig';

export const defaultTitle = 'Ad Group Settings';

export const labels = {
  nameDates: {
    label: 'Ad Group Name & Dates',
    value: advancedModals.general,
  },
  budget: {
    label: 'Ad Group Budget',
    value: 'budget',
    disabled: true,
  },
  bidStrategy: {
    label: 'Bid Strategy',
    value: advancedModals.bidStrategy,
  },
  geography: {
    label: 'Geography',
    value: advancedModals.geography,
  },
  audience: {
    label: 'Audience',
    value: advancedModals.audience,
  },
  customInventory: {
    label: 'Custom Inventory',
    value: advancedModals.customInventory,
  },
  inventory: {
    label: 'Inventory',
    value: advancedModals.inventory,
  },
  creative: {
    label: 'Creative',
    value: advancedModals.creative,
  },
};

export const menuLabels = {
  duplicate: 'Duplicate Ad Group',
  delete: 'Delete Ad Group',
};

export const nodes = [
  labels.nameDates,
  labels.budget,
  labels.bidStrategy,
  labels.geography,
  labels.audience,
  labels.inventory,
  labels.creative,
];

export const targetingFields = [
  fields.advancedInventory.path,
  fields.social.path,
  fields.advancedAudience.path,
  fields.advancedCustomInventory.path,
  fields.inventoryOption.path,
  fields.configureAdGroupDayparting.path,
  fields.timezone.path,
  fields.geo.path,
];

export const bundleTypes = {
  NONE: 'None',
  CATEGORY: 'Category',
  GENRE: 'Genre',
  NETWORK: 'Network',
  SPECIALITY: 'Speciality',
  PEACOCK: 'Peacock',
  PRIVATE: 'Private',
};
